import { Router } from '@angular/router';

import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/firebase/auth.service';
import { DOCUMENT } from '@angular/common';
import { SessionService } from '../../session.service';
import { Configuration } from '../../constants';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';

var body = document.getElementsByTagName("body")[0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ReactiveFormsModule]

})
export class HeaderComponent implements OnInit {
  lang;
  public menuItems: Menu[];
  public items: Menu[];
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public openNav: boolean = false
  public right_sidebar: boolean = false
  public text: string;
  public elem;
  public isOpenMobile: boolean = false
  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  analysisGroup: FormGroup
  constructor(public httpClient: HttpClient,
    public router: Router,
    public sessionService: SessionService,
    public navServices: NavService, private modalService: NgbModal,
    @Inject(DOCUMENT) private document: any,
    private translate: TranslateService,
    public authService: AuthService) {
    translate.setDefaultLang('en');
  }


  ngOnDestroy() {
    this.removeFix();
  }


  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage(lang) {
    this.translate.use(lang)
  }
  loadLanguage(lang){
    localStorage.setItem('lang',lang)
window.location.reload();
  }
  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return this.menuItems = [];
    let items = [];
    term = term.toLowerCase();
    this.items.filter(menuItems => {
      if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
        items.push(menuItems);
      }
      if (!menuItems.children) return false
      menuItems.children.filter(subItems => {
        if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon
          items.push(subItems);
        }
        if (!subItems.children) return false
        subItems.children.filter(suSubItems => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon
            items.push(suSubItems);
          }
        })
      })
      this.checkSearchResultEmpty(items)
      this.menuItems = items
    });
  }

  checkSearchResultEmpty(items) {
    if (!items.length)
      this.searchResultEmpty = true;
    else
      this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    body.classList.add("offcanvas");
  }
  SignOut() {
    this.sessionService.destroy(Configuration.LoggedUser);
    // this.router.navigate(['/login'])
    localStorage.clear();

    this.router.navigate(['/authentication/login/video']);
  }
  lockout() {
    this.router.navigate(['/authentication/unlockuser']);
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove("offcanvas");
    this.text = "";
  }
  ngOnInit() {
    this.lang=localStorage.getItem('lang') || 'en';
    this.elem = document.documentElement;
    this.navServices.items.subscribe(menuItems => {
      this.items = menuItems
    });
    this.initIntervals();
    this.getStatus();

  }
  datafetchStatus;
getStatus(){

    this.httpClient.get<any>(Configuration.datafetch).subscribe(
      response => {
        console.log("list response",typeof(response),response);
      this.datafetchStatus =response;
      });
        // this.list = response;
      
  
}
  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  intervaliList = [
    // {name:'1 Sec',value:1},
    { name: '3 Sec', value: 3 },
    { name: '5 Sec', value: 5 },
    { name: '10 Sec', value: 10 },
    { name: '20 Sec', value: 20 },
    { name: '30 Sec', value: 30 },
    { name: '60 Sec', value: 60 },
    { name: '5 Min', value: 300 },
    { name: '10 min', value: 600 },
    { name: '15 min', value: 600 },
  ]

  intervaliListLineRealTime = [
    { name: '30 Sec', value: 30 },
    { name: '60 Sec', value: 60 },
    { name: '5 Min', value: 300 },
    { name: '10 min', value: 600 },
    { name: '15 min', value: 600 },
  ]
  dashboardinterval;
  dashboardintervalalerts;
  machinedashboard;
  linerealtime;
  alerts
  initIntervals() {
    this.analysisGroup = new FormGroup({
      dashboardinterval: new FormControl(this.dashboardinterval ? this.dashboardinterval : ''),
      dashboardintervalalerts: new FormControl(this.dashboardintervalalerts ? this.dashboardintervalalerts : ''),
      machinedashboard: new FormControl(this.machinedashboard ? this.machinedashboard : ''),
      linerealtime: new FormControl(this.linerealtime ? this.linerealtime : ''),

      alerts: new FormControl(this.alerts ? this.alerts : ''),
      // toTime: new FormControl(""),
      // // machineId: new FormControl(this.currentMachine),
      // shift: new FormControl("DAY"),
      // lossCategory: new FormControl(""),
      // lossSubCategory: new FormControl(""),
      // shiftselected:new FormControl("")
    });
  }
  saveIntervals(data) {
    if (data) {
      localStorage.setItem('dashboardinterval', data.dashboardinterval)
      localStorage.setItem('dashboardintervalalerts', data.dashboardintervalalerts)
      localStorage.setItem('machinedashboard', data.machinedashboard)
      localStorage.setItem('linerealtime', data.linerealtime)

      localStorage.setItem('alerts', data.alerts)
      // localStorage.setItem('dashboardintervalalerts',data.dashboardintervalalerts)


    }
    Swal.fire('Good Job...', 'Time Interval Updated succesfully!', 'success')          // this.adminUserData.reset();
    window.location.reload();
    this.analysisGroup.reset();
    this.modalService.dismissAll();

  }
  // importModal(template) {
  //   this.importModalRef = this.modalService.show(template);
  //   this.dashboardinterval=localStorage.getItem('dashboardinterval')
  //   this.dashboardintervalalerts=localStorage.getItem('dashboardintervalalerts')
  //   this.machinedashboard=localStorage.getItem('machinedashboard')
  //   this.alerts=localStorage.getItem('alerts')
  //   setTimeout(() => {
  //         this.initIntervals();

  //   }, 1000);
  // }
  // closeImportEntriesDialog() {
  //   this.importModalRef.hide();
  // }
  closeResult: string;
  importModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.dashboardinterval = localStorage.getItem('dashboardinterval')
    this.dashboardintervalalerts = localStorage.getItem('dashboardintervalalerts')
    this.machinedashboard = localStorage.getItem('machinedashboard')
    this.alerts = localStorage.getItem('alerts')
    this.linerealtime = localStorage.getItem('linerealtime')
    this.initIntervals();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
