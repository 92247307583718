const Configuration = {
  SessionAcessToken: 'user_access_token',
  SessionRefreshToken: 'user_refresh_token',
  UserId: 'user_id',
  UserName: 'user_name',
  LoggedUser: 'user_logged_in',
  LoggedEmployee: 'employee_logged_in',
  ActiveSchool: 'active_school',
  PlantId: 'plant_id',
  host: '',

  apiVersion: '',
  pageSize: 12,
  // :4200http://3.1.163.189:2020/
  apiPath: 'http://3.1.163.189:2121',
  machinePath: 'http://3.1.163.189:2121',
  loginpath: 'http://3.1.163.189:2121/login',
  machineImg: 'http://3.1.163.189:1900',
  getmachineImg: 'http://3.1.163.189:1900/uploads/',
  datafetch: 'http://3.1.163.189:2121/machinelivestatus',
  imgpath: 'http://3.1.163.189:1900/uploads/',
  AuthPath: 'http://3.1.163.189:2121/oauth',
  partialPath: 'http://3.1.163.189:2121/',
  mvm: 'http://3.1.163.189:2121',
  lossanalysis: 'http://3.1.163.189:2121',
};


export {
  Configuration
};

// http://3.1.163.189:2121/