<!-- Page Header Start-->
<div class="page-main-header" [ngClass]="{'open' : navServices.collapseSidebar}" id="page-main-header">
  <div class="main-header-right row" style="background-color: #2f3c4e;">
    <div class="main-header-left d-lg-none">
      <!-- <div class="logo-wrapper"><a href="javascript:void(0)"><img src="assets/images/endless-logo.png" style="height: 72px;" alt=""> </a>BREVIUM</div> -->
    </div>
    <div class="mobile-sidebar">
      <div class="media-body text-right switch-sm">
        <label class="switch" style="margin-top: 2%;">
          <a href="javascript:void(0)" (click)="collapseSidebar()">
            <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons>
          </a>
        </label>
      </div>

    </div>
    <!-- <div class="media-body text-right switch-sm">
      <label class="switch">
       <h4 style="width: 400px;font-family: Roboto;margin-top: -20%;">SKF BNG BREVIUM</h4>
      </label>
    </div> -->
    <div class="nav-right col">


      <ul class="nav-menus" [ngClass]="{'open' : openNav}">
        <li>
          <label class="switch">
            <h4
              style="width: max-content;font-family: Roboto;    color: khaki;  font-size: larger;    margin-top: 25%;">
              SKF BREVIUM</h4>
          </label>
        </li>
        <li style="    display: flex; ">

          <span style="font-size:24px;color:white !important; cursor: pointer;" (click)="importModal(simulationModal)">
            <i class="icofont icofont-ui-timer"></i> </span>

          <!-- <span style="font-size:24px;color:white !important; cursor: pointer;"  (click)="importModal(language)">
              <i class="icofont icofont-font"></i> </span> -->
          <a href="javascript:void(0)" style="    margin-left: 9px;" class="text-dark" (click)="toggleFullScreen()">

            <app-feather-icons [icon]="'maximize'"></app-feather-icons>
          </a>
          <!-- <div *ngIf="datafetchStatus.statustag == 0" class="circle red"></div>
          <div *ngIf="datafetchStatus.statustag == 1" class="circle green"></div> -->

        </li>
        <!-- <li class="onhover-dropdown">
          <a class="txt-dark" href="javascript:void(0)">
            <h6>EN</h6>
          </a>
          <ul class="language-dropdown onhover-show-div p-20">
            <li><a href="javascript:void(0)" data-lng="en" (click)="changeLanguage('en')"><i
                  class="flag-icon flag-icon-is"></i> English</a></li>
            <li><a href="javascript:void(0)" data-lng="es" (click)="changeLanguage('es')"><i
                  class="flag-icon flag-icon-um"></i> Spanish</a></li>
            <li><a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')"><i
                  class="flag-icon flag-icon-uy"></i> Portuguese</a></li>
            <li><a href="javascript:void(0)" data-lng="fr" (click)="changeLanguage('fr')"><i
                  class="flag-icon flag-icon-nz"></i> French</a></li>
          </ul>
        </li> -->
        <!-- <li class="onhover-dropdown">
          <app-feather-icons [icon]="'bell'"></app-feather-icons><span class="dot"></span>
          <ul class="notification-dropdown onhover-show-div">
            <li>Notification <span class="badge badge-pill badge-primary pull-right">3</span></li>
            <li>
              <div class="media">
                <div class="media-body">
                  <h6 class="mt-0"><span>
                      <app-feather-icons class="shopping-color" [icon]="'shopping-bag'"></app-feather-icons>
                    </span>Your order ready for Ship..!<small class="pull-right">9:00 AM</small></h6>
                  <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <div class="media-body">
                  <h6 class="mt-0 txt-success"><span>
                      <app-feather-icons class="download-color font-success" [icon]="'download'"></app-feather-icons>
                    </span>Download Complete<small class="pull-right">2:30 PM</small></h6>
                  <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <div class="media-body">
                  <h6 class="mt-0 txt-danger"><span>
                      <app-feather-icons class="alert-color font-danger" [icon]="'alert-circle'"></app-feather-icons>
                    </span>250 MB trush files<small class="pull-right">5:00 PM</small></h6>
                  <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                </div>
              </div>
            </li>
            <li class="bg-light txt-dark"><a href="javascript:void(0)">All</a> notification</li>
          </ul>
        </li> -->
        <!-- <li><a>
            <app-feather-icons class="right_side_toggle" [icon]="'message-circle'" (click)="right_side_bar()">
            </app-feather-icons><span class="dot"></span>
          </a></li> -->
        <li class="onhover-dropdown">
          <div class="media align-items-center" *ngIf="!authService.userData as user"><img
              class="align-self-center pull-right img-50 rounded-circle" src="../../../../assets/images/emp2.jpeg"
              alt="header-user">
            <div class="dotted-animation"><span class="animate-circle"></span><span class="main-circle"></span></div>
          </div>
          <div class="media align-items-center" *ngIf="authService.userData as user"><img
              class="align-self-center pull-right img-50 rounded-circle"
              [src]="user.photoURL ==null ? 'assets/images/user/1.jpg' : user.photoURL" alt="header-user">
            <div class="dotted-animation"><span class="animate-circle"></span><span class="main-circle"></span></div>
          </div>
          <ul class="profile-dropdown onhover-show-div p-20">
            <!-- <li><a href="javascript:void(0)">
                <app-feather-icons [icon]="'user'"></app-feather-icons>Edit Profile
              </a></li>
            <li><a href="javascript:void(0)">


                <app-feather-icons [icon]="'mail'"></app-feather-icons>Inbox
              </a></li> -->
            <li><a href="javascript:void(0)" (click)="lockout()">
                <app-feather-icons [icon]="'lock'"></app-feather-icons>{{'Lock Screen' | translate}}
              </a></li>
            <!-- <li><a href="javascript:void(0)">
                <app-feather-icons [icon]="'settings'"></app-feather-icons>Settings
              </a></li> -->
            <li><a href="javascript:void(0)" (click)="SignOut()">
                <app-feather-icons [icon]="'log-out'"></app-feather-icons>{{'Logout' | translate}}
              </a></li>
          </ul>
        </li>
      </ul>
      <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
        <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->






<ng-template #simulationModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title2">{{'Time Interval Setting' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" style="color:white;background: inherit !important"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="background:white">

    <form [formGroup]="analysisGroup">
      <div class="row">
        <div class="col-6">
          <label for="">{{'Plant & Line Dashboard' | translate}} </label><br>

          <!-- <mat-form-field floatLabel='never' style="    width: 90%; "> -->
          <select placeholder="{{'Plant & Line Dashboard' | translate}}" class="form-control"
            formControlName="dashboardinterval" id="sel1">
            <option style="z-index: 1000000;" *ngFor="let data of intervaliList" [value]="data.value">
              {{data?.name}} </option>
          </select>
          <!-- </mat-form-field> -->


        </div>
        <div class="col-6">
          <label for="">{{'Plant & Line Dashboard Alerts' | translate}}</label><br>

          <!-- <mat-form-field floatLabel='never' style="    width: 90%; "> -->
          <select placeholder="{{'Plant & Line Dashboard  Alerts' | translate}}" class="form-control"
            formControlName="dashboardintervalalerts" id="sel1">
            <option style="z-index: 1000000;" *ngFor="let data of intervaliList" [value]="data.value">
              {{data?.name}} </option>
          </select>
          <!-- </mat-form-field> -->


        </div>
        <div class="col-6">
          <label for="">{{'Machine Dashboard' | translate}}</label><br>

          <!-- <mat-form-field floatLabel='never' style="    width: 90%; "> -->
          <select placeholder="{{'Machine Dashboard' | translate}}" class="form-control"
            formControlName="machinedashboard" id="sel1">
            <option style="z-index: 1000000;" *ngFor="let data of intervaliList" [value]="data.value">
              {{data?.name}} </option>
          </select>
          <!-- </mat-form-field> -->


        </div>
        <div class="col-6">
          <label for="">{{'Line Real Time' | translate}}</label><br>

          <!-- <mat-form-field floatLabel='never' style="    width: 90%; "> -->
          <select placeholder="{{'Line Real Time' | translate}}" class="form-control" formControlName="linerealtime"
            id="sel1">
            <option style="z-index: 1000000;" *ngFor="let data of intervaliListLineRealTime" [value]="data.value">
              {{data?.name}} </option>
          </select>
          <!-- </mat-form-field> -->


        </div>
        <div class="col-6">
          <label for="">{{'Alerts' | translate}}</label><br>

          <!-- <mat-form-field floatLabel='never' style="    width: 90%; "> -->
          <select placeholder="{{'Alerts' | translate}}" class="form-control" formControlName="alerts" id="sel1">
            <option style="z-index: 1000000;" *ngFor="let data of intervaliList" [value]="data.value">
              {{data?.name}} </option>
          </select>
          <!-- </mat-form-field> -->


        </div>

      </div>
    </form>
  </div>

  <div class="modal-footer text-center">
    <button data-dismiss="modal" style="float:right" class="btn btn btn-outline-success"
      (click)="saveIntervals(analysisGroup.value)">{{'Save' | translate}}</button>
  </div>
</ng-template>

<ng-template #language let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title2">{{'Select Language' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" style="color:white;background: inherit !important"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="background:white">

    <form>
      <div class="row">
        <div class="col-6">
          <label for="">{{'Select' | translate}} </label><br>

          <!-- <mat-form-field floatLabel='never' style="    width: 90%; "> -->
          <select placeholder="{{'Select' | translate}}" (change)="loadLanguage($event.target.value)"
            class="form-control">
            <option style="" value="en" [selected]="lang == 'en'">English </option>
            <option style="" value="es" [selected]="lang == 'es'">Spanish </option>
            <option style="" value="fr" [selected]="lang == 'fr'">French </option>

          </select>
          <!-- </mat-form-field> -->


        </div>


      </div>
    </form>
  </div>

  <div class="modal-footer text-center">
  </div>
</ng-template>