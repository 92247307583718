import { Menu1, Nav2Service1 } from './../../services/nav2.service';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { local } from 'd3';
// import { NavService, Menu } from '../../services/nav.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
  loggeduser;
  username;
  menulist =[];
  public menuItems: Menu1[];
  public url: any;
  public fileurl: any;
  constructor(private router: Router, public navServices: Nav2Service1) {
    this.loggeduser = localStorage.getItem('userrole')
    this.username = localStorage.getItem('username')


    this.navServices.items1.subscribe(menuItems => {
      if(this.loggeduser == 'Admin'){

        this.menuItems =menuItems;
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.menuItems.filter(items => {
              if (items.path === event.url)
                this.setNavActive(items)
              if (!items.children) return false
              items.children.filter(subItems => {
                if (subItems.path === event.url)
                  this.setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                  if (subSubItems.path === event.url)
                    this.setNavActive(subSubItems)
                })
              })
            })
          }
        })
      }

      else if(this.loggeduser == 'Normal_Prod'){
        menuItems.forEach((items,i)=>{
          // console.log("items",menuItems[0])
          if(i == 0){
              this.menulist.push(items)

            
        

          }

        })
        this.menulist[0].children.splice(4, 1)
        this.menuItems=this.menulist;
        console.log("this.menuItems",this.menuItems)
                  
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.menuItems.filter(items => {
              if (items.path === event.url)
                this.setNavActive(items)
              if (!items.children) return false
              items.children.filter(subItems => {
                if (subItems.path === event.url)
                  this.setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                  if (subSubItems.path === event.url)
                    this.setNavActive(subSubItems)
                })
              })
            })
          }
        })
      }
      else{
        menuItems.forEach((items,i)=>{
          // console.log("items",menuItems[0])
          if(i ==1 || i == 0){
            this.menulist.push(items)
        

          }
          console.log("this.menulist",this.menulist)

        })
        this.menuItems=this.menulist;
        console.log("this.menuItems",this.menuItems)
                  
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.menuItems.filter(items => {
              if (items.path === event.url)
                this.setNavActive(items)
              if (!items.children) return false
              items.children.filter(subItems => {
                if (subItems.path === event.url)
                  this.setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                  if (subSubItems.path === event.url)
                    this.setNavActive(subSubItems)
                })
              })
            })
          }
        })
      }
      console.log("menu items ",this.menuItems,this.loggeduser)

    })
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

}
