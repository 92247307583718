import { Injectable } from '@angular/core';

@Injectable()
export class SessionService {
    set(SessionAcessToken, value) {
        return localStorage.setItem(SessionAcessToken, value);
    }

    get(SessionAcessToken) {
        return localStorage.getItem(SessionAcessToken);
    }

    destroy(key) {
        return localStorage.removeItem(key);
    }
}