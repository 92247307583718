import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { SessionService } from '../../../shared/session.service';
import { Configuration } from '../../../shared/constants';
declare const $: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],

})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  hide = true;
  ress :any = {};
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router, private http: HttpClient,public sessionService:SessionService
  ) {}
  url =Configuration.loginpath;
  ngOnInit() {
    localStorage.setItem('mainurl',Configuration.loginpath);
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: ['']
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    //    [Focus input] * /
    $('.input100').each(function() {
      $(this).on('blur', function() {
        if (
          $(this)
            .val()
            .trim() != ''
        ) {
          $(this).addClass('has-val');
        } else {
          $(this).removeClass('has-val');
        }
      });
    });
  }
  get f() {
    return this.loginForm.controls;
  }
  showErrorAlert() {
    Swal.fire('Yikes!', 'username or password  went wrong!', 'error')
  }
  defaultselection;
  radiotoggle(data){
    console.log("data",data)
    this.defaultselection=data;
  }
  onSubmit() {
    this.submitted = true;
console.log("clicked")
    // stop here if form is invalid
    if (this.loginForm.invalid) {


      return;
    } else {



        this.http.post(this.url, this.loginForm.value).subscribe(
          res => {
            console.log(res);
            this.ress = res
            this.sessionService.set(Configuration.SessionAcessToken, this.ress.token);
            this.sessionService.set(Configuration.LoggedUser, JSON.stringify(this.ress));
            localStorage.setItem('username', this.ress.username);
            localStorage.setItem('dept', this.ress.dept);

            localStorage.setItem('userid', this.ress.id);
            localStorage.setItem('usertoken', this.ress.token);
            localStorage.setItem('userrole', this.ress.role);
            localStorage.setItem('mainurl',Configuration.loginpath);
            localStorage.setItem('dashboardinterval','300')
            localStorage.setItem('dashboardintervalalerts','3')
            localStorage.setItem('machinedashboard','300')
            localStorage.setItem('linerealtime','30')
      
            localStorage.setItem('alerts','60')
            console.log('userrole', this.ress.role);
            // tslint:disable-next-line: triple-equals
            if (this.ress.role == 'Admin' ||  this.ress.role == 'admin' ){
              if(this.defaultselection== 'admin'){
                this.router.navigate(['/config/thing']);

              }
             else if(this.defaultselection== 'plant'){
                this.router.navigate(['/dashboard/metaline']);

              }else if(this.defaultselection== 'alert'){

                this.router.navigate(['/config/alertimg']);

              }
              else {
                this.router.navigate(['/dashboard/planthome']);

              }


              }
              else if (this.ress.role == 'Supervisor'){
                
                if(this.defaultselection== 'plant'){
                  this.router.navigate(['/dashboard/metaline']);
  
                }else if(this.defaultselection== 'alert'){

                  this.router.navigate(['/config/alertimg']);
  
                }
              }else{
                this.router.navigate(['/dashboard/planthome']);

              }
          },
          (err: HttpErrorResponse) => {
            console.log(err.error);
            this.showErrorAlert();
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
          }
        );


    }
  }

}
