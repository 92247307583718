import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { SessionService } from './session.service';
import { Configuration } from './constants';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class HttpService {

    constructor(private http: HttpClient, private sessionService : SessionService) {}

    getHttpClientOptions(){
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': 'Bearer ' + this.sessionService.get(Configuration.SessionAcessToken),
            })
          };
        return httpOptions;
    }


    get(url, callback){
        this.http.get(url, this.getHttpClientOptions())
        .subscribe((response) => {
            callback(this.extractData(response));
        },
        (error: HttpErrorResponse) => {
            callback(error)
            // this.toaster.warning(error.error.message)
        });
    }

    post(url, data, callback) {
        this.http.post(url, data, this.getHttpClientOptions())
        .subscribe((response) => {
            callback(this.extractData(response));
        },
        (error: HttpErrorResponse) => {
            callback(this.extractData(error));
        });
    }

    delete(url,callback){
        this.http.delete(url, this.getHttpClientOptions())
        .subscribe((response : Response)=>{
            callback(this.extractData(response));
        },
        (error: HttpErrorResponse)=>{
            callback(this.extractData(error))
        });
    }
    
    put(url, data, callback) {
        this.http.put(url , data, this.getHttpClientOptions())
        .subscribe((response: Response) => {
            callback(this.extractData(response));
        },
        (error: HttpErrorResponse) => {
            callback(this.extractData(error));  
        });
    }

    
    private extractData(response) {
        // if(response != null && response['_body'] != ""){
        //     response['data'] = JSON.parse(response['_body']);
        // }
        return response;
    }

}